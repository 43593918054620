body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.componente {
  max-width: 97vw;
  margin: 1em auto 0 auto;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Sobreescribe clases del header de la grilla de psicólogos */

.MuiDataGrid-colCellTitle {
  white-space: break-spaces !important;
  line-height: 1.3em;
  width: 100%;
  text-align: center;
}

.MuiDataGrid-iconButtonContainer {
  line-height: 1.3em;
}

.MuiIconButton-sizeSmall {
  padding: 0 3px !important;
}

.MuiDataGrid-colCellSortable {
  padding: 0 5px !important;
}
